<template>

  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-head">
            <a-form layout="inline">
              <a-form-item>
                <a-button type="primary" @click="addAction" icon="plus">添加产品</a-button>
              </a-form-item>
            </a-form>
          </div>
          <div class="page-cu-left-table">
            <vuescroll>
              <a-table :columns="columns" :data-source="list" rowKey="id" bordered :pagination="false">
                <span slot="img_url" slot-scope="text, record">
                    <small-image-list :img_url="text"/>
                </span>
                <span slot="is_index" slot-scope="text, record">
                    <span v-if="text == '1'">否</span>
                    <span v-else>是</span>
                </span>
                <span slot="action" slot-scope="text, record">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click.stop.prevent="e => e.preventDefault()">
                      更多操作
                      <a-icon type="down"/>
                    </a>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a-button type="link" @click="openEditDialog(record.id)" icon="edit">编辑</a-button>
                      </a-menu-item>
                      <a-menu-divider/>
                      <a-menu-item>
                        <a-button type="link" @click="delConfirm(record.id)" icon="delete">删除</a-button>
                      </a-menu-item>
                      <a-menu-divider/>
                      <a-menu-item>
                        <a-button type="link" @click="recommendConfirm(record)" icon="delete">{{ record.is_index == '1'? '推荐到首页' : '取消推荐到首页' }}</a-button>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </span>

              </a-table>
              <div class="page-cu-left-page">
                <a-pagination
                  v-model="page.start"
                  :page-size.sync="page.pageSize"
                  :total="page.totalRow"
                  @change="changePage"
                />
              </div>
            </vuescroll>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-modal title="产品管理" :width="800" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading"
             :visible="actionVisible" @ok="actionData" ok-text="确认" cancel-text="取消" @cancel="cancel">
      <a-form-model ref="formModel" :rules="formModelRules" :model="formModel" layout="vertical"
                    :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="formModel.title" placeholder="请输入标题"></a-input>
        </a-form-model-item>
        <a-form-model-item label="图片" prop="img_url">
          <a-alert message="图片尺寸: 750px * 650px" banner  style="margin-bottom: 20px"/>
          <custom-file-list
            :img_url.sync="formModel.img_url"
            :type="'single'"
            @changeCustomFile="changeImgUrl"/>
        </a-form-model-item>
        <a-form-model-item label="内容" prop="content">
          <a-textarea v-model="formModel.content" :rows="4" placeholder="请输入内容"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="详情" prop="content_detail">
          <custom-editor v-bind:content.sync="formModel.content_detail"/>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sort">
          <a-input v-model="formModel.sort" type="number" placeholder="请输入序号"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
  import * as Api from './api';
  import axios from 'axios';

  export default {
    name: "index",
    data() {
      return {
        formModel: {
          title: '',
          img_url: '',
          content: '',
          content_detail: '',
          sort: ''
        },
        formModelRules: {
          title: [{required: true, message: '请输入标题', trigger: 'change'}],
          img_url: [{required: true, message: '请上传图片', trigger: 'change'}],
          content: [{required: true, message: '请输入介绍', trigger: 'change'}],
          content_detail: [{required: true, message: '请输入详情', trigger: 'change'}],
          sort: [{required: true, message: '请输入序号', trigger: 'change'}],
        },
        columns: [
          {
            title: '标题',
            dataIndex: 'title',
          },
          {
            title: '图片',
            dataIndex: 'img_url',
            scopedSlots: {customRender: 'img_url'}
          },
          {
            title: '内容',
            dataIndex: 'content',
          },
          {
            title: '推荐首页',
            dataIndex: 'is_index',
            scopedSlots: {customRender: 'is_index'}
          },
          {
            title: '序号',
            dataIndex: 'sort',
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
          },
          {
            title: '操作',
            width: 150,
            scopedSlots: {customRender: 'action'},
          },
        ],
        page: {
          start: 1,
          totalRow: 0,
          pageSize: 0,
          limit: 20
        },
        list: [],
        fileList: [],
        actionVisible: false,
      }
    },
    mounted() {
      this.getList();

    },
    methods: {
      /**
       * 图片上传完成的事件
       * @author wheat
       * */
      changeImgUrl(url) {
        this.formModel.img_url = url;
      },
      /**
       * 添加，更新
       * @author wheat
       */
      actionData() {
        this.$refs.formModel.validate(async valid => {
          if (valid) {
            let res = null;
            if (this.formModel['id']) {
              res = await Api.Update(this.formModel);
            } else {
              res = await Api.Save(this.formModel);
            }

            if (res && res['code'] == '0') {
              this.$message.success(res.message);
              this.actionVisible = false;
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          }
        });
      },
      /**
      * 表单重置
      * @author
      */
      resetFormModel() {
        for (let key in this.formModel) {
          this.formModel[key] = '';
        }
      },
      /**
       * 打开窗口
       * @author wheat
       * */
      addAction() {
        this.resetFormModel();
        this.actionVisible = true;
      },
      /**
       * 关闭穿口
       * @author wheat
       * */
      cancel() {
        this.actionVisible = false;
      },

      /**
       * 删除
       * @author wheat
       * */
      async delConfirm(id) {
        let that = this;
        this.$confirm({
          title: '信息提示',
          okType: 'danger',
          content: '您确定要删除吗?',
          async onOk() {
            let res = await Api.Delete({id: id})
            if (res && res.code == '0') {
              that.$message.success(res.message);
              that.getList();
            } else {
              that.$message.error(res.message);
            }
          },
          onCancel() {
          },
        });
      },
      /**
       * 推荐/取消到首页
       * @author wheat
       * */
      recommendConfirm(record) {
        let that = this;
        this.$confirm({
          title: '信息提示',
          okType: 'danger',
          content: record.is_index == '1' ? '您确定要推荐到首页吗?' : '你确定要取消推荐到首页吗?',
          async onOk() {
            let res = await Api.ChangeIndex({id: record.id, is_index: record.is_index == '1' ? '2' : '1'})
            if (res && res.code == '0') {
              that.$message.success(res.message);
              that.getList();
            } else {
              that.$message.error(res.message);
            }
          },
          onCancel() {
          },
        });
      },
      /**
       * 获取列表
       * @author wheat
       * */
      async getList() {
        let res = await Api.List({
          start: this.page.start,
          limit: this.page.limit,
        })
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
        this.list = res.page.list;
      },
      /**
       * 分页切换
       * @author wheat
       * */
      changePage(currentPage) {
        this.page.start = currentPage;
        this.getList();
      },
      /**
       * 获取编辑内容
       * @author wheat
       * */
      async openEditDialog(id) {
        this.resetFormModel()
        let res = await Api.Detail({id: id});
        if (res && res['code'] == '0') {
          this.formModel = Object.assign({}, res['target']);
          this.actionVisible = true;
        }
        this.$message.success(res.message);
      },
    },
  }
</script>

<style scoped>

</style>
